import React, { Component } from 'react';
import {connect} from 'react-redux';
import Lottie from 'react-lottie';
import * as animationData from '../anim/happy_gift.json';

class Redeem extends Component {
  
  constructor(props){
    super(props);
    this.state={
      openLottie:false,
      lottieOpacity:0
    }
    this._isMounted = false;
  }

  componentDidMount(){
    this._isMounted = true;
    if(this._isMounted){
      this.setState({
        openLottie:true,
        lottieOpacity:1
      });
    }
  }
  
  componentWillUnmount() {
    this._isMounted = false;
  }
  
  componentDidUpdate(oldProps) {
    const newProps = this.props
    /*if(oldProps.field !== newProps.field) {
      this.setState({ ...something based on newProps.field... })
      setTimeout(()=>{
        this.setState({
          lottieOpacity:0
        });
        window.scrollTop = 0;
        setTimeout(()=>{
          this.setState({
            openLottie:false
          });
        }, 300);
      }, 2800);
    }*/
    if(newProps.rstatus){
      setTimeout(()=>{
        this.setState({
          lottieOpacity:0
        });
        window.scrollTop = 0;
        setTimeout(()=>{
          this.setState({
            openLottie:false
          });
        }, 300);
      }, 2800);
    }
  }

  render() {
    //console.log(this.props.clients);
    
    const defaultOptions = {
      loop: false,
      autoplay: true, 
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    
    let redeemmsg = "",
        redeemcolor = "#cccccc",
        redeemhash = "";
    switch (this.props.rstatus && this.props.rstatus.tmp_redeem_active) {
      case 0:
        redeemmsg = "You've redeemed "+this.props.rstatus.name+"!";
        redeemcolor = "rgb(81, 127, 164)";
        redeemhash = this.props.rstatus.hash;
        break;
      case 3:
        redeemmsg = "You've won "+this.props.rstatus.name+"!";
        redeemcolor = "#6435c9";
        redeemhash = this.props.rstatus.hash;
        break;
      case 4:
        redeemmsg = "Sorry! You didn't get "+this.props.rstatus.name+"... Try again next time!";
        redeemcolor = "#c1be84";
        redeemhash = this.props.rstatus.hash;
        break;
      default:
        redeemmsg = "Test Redeem"
        break;
    }
    return (
      <div className="redeemMain">
        <div className="redeemMsg" style={{backgroundColor:redeemcolor}}>
          {redeemmsg}
        </div>
        <div className="redeemHash">
          {(this.props.rstatus)?"#"+redeemhash:null}
        </div>
        
        {(this.state.openLottie) ? 
          <div className="lottieAnim" style={{opacity:this.state.lottieOpacity}}>
              <Lottie options={defaultOptions}
                isStopped={!this.state.openLottie}
                height={400}
                width={400}
                eventListeners={
                  [
                    {
                      eventName: 'click',
                      callback: () => console.log('do nothing!'),
                    },
                  ]
                }
              />
          </div>
          : null}
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    rstatus:state.Events.rstatus
  };
}

export default connect(mapStateToProps)(Redeem);