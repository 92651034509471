export const CHANGE_MODE = "CHANGE_MODE";
export const READ_SIGNIN = "READ_SIGNIN";
export const READ_QUESTIONS = "READ_SURVEY";
export const CREATE_OPTIONS = "CREATE_OPTIONS";
export const CREATE_CHAT = "CREATE_CHAT";

export const LOADING = "LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

export const UPDATE_USER = "UPDATE_USER";

export const REDEEM_PRIZE = "REDEEM_PRIZE";

export const UPDATE_USER_TYPE = 3;
export const READ_SIGNIN_TYPE = 2;
export const REDEEM_PRIZE_TYPE = 4;
export const READ_QUESTIONS_TYPE = 5;
export const CREATE_OPTIONS_TYPE = 6;
export const CREATE_CHAT_TYPE = 7;

export var token = null;
try {
  token = sessionStorage.getItem("token");
} catch (err){
  
}
export function ChangeToken(t){
  token = t;
  sessionStorage.setItem("token", t);
}

export const PUBLIC_PATH = "stella/public";
export const MESSAGE_PATH = "stella/message";