import * as types from "./types";
export const DEV = false;
//CHECK App.js for the REDUX devtool

let CONNECT = null;
if(DEV){
  CONNECT = {
    dev:"http://localhost:3001/"
    //dev:"http://192.168.0.10:3001/"
  }
} else {
  CONNECT = {
    dev:"https://api.qoouo.com/call/"
  }
}

export async function mfetch(data){
  //console.log(data);
  
  if(types.token){
    data.data.token = types.token;
  }
  //console.log("connect", data);
  let param = data.param,
      mdata = JSON.stringify(data.data);
  //console.log("connecting2", data, CONNECT.dev+param);
  try {
    var resp =  await fetch(CONNECT.dev+param, {
      method:"POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body:mdata
    })
    var json = await resp.json();
    //console.log("connected", json);
    if(json.status && json.status === -1){
      //window.location.reload();
      return false;
    }
    if(types.token !== json.token){
      types.ChangeToken(json.token);
    }
    return json;
  } catch (err){
    console.log(err);
    return err;
  }
  
}