import React, { Component } from 'react';

import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import { Swipeable } from 'react-swipeable';
import {Icon, Button} from 'semantic-ui-react';

import {CreateChat} from '../../redux/actions/users';
import Notify from './Notify';

class Carousel extends Component {
  
  constructor(props){
    super(props);
    this.state={
      ind:0,
      left:0,
      ni:null,
      title:null,
      subtitle:null,
      active:false
    }
  
    this.cs=null;
    this.anim=false;
    this.timer=null;
  }

  componentDidMount(){
    this.startTimer();
  }
  
  componentWillUnmount(){
    if(this.timer){
      clearTimeout(this.timer);
      this.timer=null;
    }
  }
  
  startTimer=()=>{
    if(this.timer){
      clearTimeout(this.timer);
      this.timer=null;
    }
    this.timer=setTimeout(()=>{
      this.next()
    },20000)
  }
  
  prev=()=>{
    if(this.anim){
      return false;
    }
    var ni = (this.props.items && this.state.ind-1 < 0)?this.props.items.length-1:this.state.ind-1;
    this.animSlide(ni, 100);
  }
  
  next=()=>{
    if(this.anim){
      return false;
    }
    var ni = (this.props.items && this.state.ind+1 >= this.props.items.length)?0:this.state.ind+1;
    this.animSlide(ni, -100);
  }
  
  //animation with timeouts
  animSlide=(ni, left)=>{
    this.anim = true;
    this.setState({
      ni:ni
    })
    
    setTimeout(()=>{
      this.setState({
        left:left
      });
      
      setTimeout(()=>{
        this.setState({
          ind:ni,
          ni:null,
          left:0
        });
        this.anim = false;
        this.startTimer();
      },500);
    }, 100);
  }
  
  CopyChat=()=>{
    this.chatInp.select();
    document.execCommand('copy');
    //TO CONFIRM better UI
    alert("Copied Your Text");
  }
  
  StartChat=()=>{
    if(window.confirm("Are you sure you want to send this text and receive an SMS response later?")){
      var msg = this.state.title+" "+this.chatInp.value;
      this.props.dispatch(CreateChat(this.props.match.params.hash, msg, ()=>{
        alert("We got your message, and will SMS text you back later!")
        this.setState({
          active:false
        })
      }))
    }
  }
  
  Notify=(hash, subtext)=>{
    this.setState({
      title:"#"+hash,
      subtitle:subtext,
      active:true
    })
  }
  
  renderItems=(i,ni)=>{
    var propArr = (this.props.items)?this.props.items:null,
        lM = (0+this.state.left)+"%";
//        inP = i-1,
//        inA = i+1,
//        lP = -100-this.state.left,
//        lM = 0-this.state.left,
//        lA = 100-this.state.left;
    
    //console.log(ni,i);
    var itemL = ((ni === i+1) || (ni===0 && i===propArr.length-1)) ? 100+this.state.left+"%" : -100+this.state.left+"%";
//    inP = (inP<0)?this.props.items.length-1:inP;
//    inA = (inA>=this.props.items.length)?0:inA;
    
    
    //<div className="carouselbgs" style={{backgroundImage:"url("+propArr[in1].img+")"}}></div>
    //<div className="blocktxt">{this.props.ue.mobile}</div>
    var items = (propArr) ? 
        <div className="carouselslide">
          <div className="carouselbgs" style={{backgroundImage:"url("+propArr[i].img+")", left:lM, transition:(ni !== null)?"left 0.5s":""}}>
            <div className="bgtexts">
              <div className="title">{propArr[i].title}</div>
              <div className="description">
                {propArr[i].description}
                <a style={{marginLeft:10}} onClick={this.Notify.bind(this, 
                                    propArr[i].sms_code, 
                                    propArr[i].sms_message)}>
                ...read more
                </a>
              </div>
            </div>
          </div>
          
          {(ni !== null) ? 
            <div className="carouselbgs" style={{backgroundImage:"url("+propArr[ni].img+")", left:itemL, transition:"left 0.5s"}}>
              <div className="bgtexts">
                <div className="title">{propArr[ni].title}</div>
                <div className="description">
                  {propArr[ni].description}
                  <a style={{marginLeft:10}} onClick={this.Notify.bind(this, 
                                      propArr[i].sms_code, 
                                      propArr[i].sms_message)}>
                  ...read more
                  </a>
                </div>
              </div>
            </div>
          : null}
          <div className="chat" 
            onClick={this.Notify.bind(this, 
                                      propArr[i].sms_code, 
                                      propArr[i].sms_message)}>
            <div className='chaticon'>
              <Icon name='comment' color="blue" circular inverted size="large" />
            </div>
            <div className='chatdesc'>
              <div className="blocktxt">Questions?</div>
            </div>
          </div>
        </div>: null;
    return items;
  }
  
  renderBullets=(ind)=>{
    var bullets = this.props.items.map((obj,i)=>{
      var cn = "carouselBullets";
      if(i===ind){
        cn = "carouselBullets active"
      }
      return <div className={cn} key={i}></div>
    });
    return (
      <div className="carouselBulletsCont">
        {bullets}
      </div>
    )
  }
  
  renderChat=()=>{
    return (
      <div style={{width:"100%"}}>
        <div style={{width:"100%", marginBottom:10}}>
          <input style={{width:"90%", textAlign:"center", borderColor:"#EEE", padding:5}} ref={(ref)=>{this.chatInp = ref}} placeholder=" feedback / question"/>
        </div>
        <div>
          <Button style={{flex:1}} onClick={()=>{this.setState({active:false})}}>
            Cancel
          </Button>
          {/*
          <Button style={{flex:1}} color="yellow" onClick={this.CopyChat}>
            Copy
          </Button>
          */}
          <Button style={{flex:1}} color="blue" onClick={this.StartChat}>
            Send
          </Button>
        </div>
      </div>
    )
  }
  
  render() {
    return (
      <div className="carouselMain">
        <Swipeable className="carouselslide" onSwipedRight={this.prev} onSwipedLeft={this.next}>
          {this.renderItems(this.state.ind, this.state.ni)}
        </Swipeable>
        <div className="left" onClick={this.prev}></div>
        <div className="right" onClick={this.next}></div>
        {this.renderBullets(this.state.ind)}
        <Notify
          title={this.state.title}
          subtitle={this.state.subtitle}
          active={this.state.active}
          custcomp={this.renderChat()}
          />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    ue:state.Events.user.ue
  }
}

export default withRouter(connect(mapStateToProps)(Carousel));