import {mfetch} from '../connect';
import * as types from '../types';

export function UpdateUser(data, callback){
  
  return async (dispatch)=>{
    dispatch({type:types.LOADING});
    var json = await mfetch({param:types.PUBLIC_PATH, data:{
      hash:data.hash, 
      data:{fullname:data.fullname, email:data.email}, type:types.UPDATE_USER_TYPE}});
    
    if(json.status === true){
      dispatch(FinishUpdateUser(json.data[0]));
    }
    
    if(typeof callback === "function"){
      callback(json.data[0]);
    }
    
    dispatch({type:types.FINISH_LOADING});
  }
}

function FinishUpdateUser(ue){
  return {
    type:types.UPDATE_USER,
    ue
  }
}

export function RedeemPrize(hash, pid){
  return async (dispatch)=>{
    dispatch({type:types.LOADING});
    var json = await mfetch({param:types.PUBLIC_PATH, data:{
      hash:hash, 
      data:{pid:pid}, 
      type:types.REDEEM_PRIZE_TYPE}});
    //console.log(json);
    if(json.status === true){
      if(json.msg){
        SMSMessage(json.msg);
      }
      dispatch(FinishRedeemPrize(json.data));
    } else {
      alert("You don't have enough points!");
    }
    
    dispatch({type:types.FINISH_LOADING});
  }
}

function FinishRedeemPrize(pr){
  return {
    mode:22,
    type:types.REDEEM_PRIZE,
    pr
  }
}

export function CreateOptions(hash, sid, opts, callback){
  return async (dispatch)=>{
    dispatch({type:types.LOADING});
    var json = await mfetch({param:types.PUBLIC_PATH, data:{
      hash:hash, 
      data:{id:sid, opts:opts}, 
      type:types.CREATE_OPTIONS_TYPE}});
    //console.log(json);
    if(json.status === true){
      dispatch(FinishCreateOption(json.data));
      if(typeof callback === "function"){
        callback();
      }
    }
    
    dispatch({type:types.FINISH_LOADING});
  }
}

export function FinishCreateOption(os){
  return {
    type:types.CREATE_OPTIONS,
    os
  }
}

export function CreateChat(hash, message, callback){
  return async (dispatch)=>{
    dispatch({type:types.LOADING});
    var json = await mfetch({param:types.PUBLIC_PATH, data:{
      hash:hash, 
      data:{message:message}, 
      type:types.CREATE_CHAT_TYPE}});
    //console.log(json);
    if(json.status === true){
      if(typeof callback === "function"){
        callback();
      }
    }
    
    dispatch({type:types.FINISH_LOADING});
  }
}

async function SMSMessage(data){
  await mfetch({param:types.MESSAGE_PATH, data:{key:"msg", data:{mobile:data.mobile, from:data.from, msg:data.msg}}})
  //console.log(json);
}