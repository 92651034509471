import React, { Component } from 'react';

import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {Icon} from 'semantic-ui-react';

class SurveysCard extends Component {
  donecolor="#00b5ad";
  todocolor="#993fEd";

  render() {
    return (
       <div className="rcmain" onClick={this.props.read} >
        {(!this.props.img || this.props.img === "") ? null : <div className="rcpreview" style={{backgroundImage:"url("+this.props.img+")"}}/>}
        

        <div className="rcname">
          {(this.props.complete === 1)?
            <div className="survgifts">{this.props.opts}<Icon name="gift" /></div>:null
          }
          {this.props.title}
        </div>
        <div className="survmain" style={{backgroundColor:(this.props.complete === 1)?this.donecolor:this.todocolor}}>
          <div className="survdesc">
            {this.props.desc}
          </div>
          
          {(this.props.complete === 1)?
          <div className="survdescbuts" style={{backgroundColor:this.donecolor}}><Icon name="check" /></div>:
          <div className="survdescbuts" style={{backgroundColor:this.todocolor}}><Icon name="edit" /></div>
          }
          
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    //rewards:state.Events.user.rewards
    allpts:state.Events.user.ue.totalpts
  }
}

export default withRouter(connect(mapStateToProps)(SurveysCard));