import React, { Component } from 'react';

import {connect} from 'react-redux';
import {Icon} from 'semantic-ui-react';
import { withRouter } from 'react-router';

import {ReadHash, ChangeMode} from '../redux/actions/events';
import Promos from './Promos';
import Edit from './Edit';
import MainMenu from './Menu';
import Rewards from './rewards/Rewards';
import Redeem from './rewards/Redeem';
import Surveys from './surveys/Surveys';

import {Loader} from 'semantic-ui-react';

class MainUI extends Component {

  componentDidMount(){
    this.props.dispatch(ReadHash(this.props.match.params.hash));
  }
  
  renderPts(){
    if(!this.props.ue){
      return null;
    }
    
    //console.log(this.props.ue);
    var totalpts = this.props.ue.totalpts*1-this.props.ue.redeempts*1+this.props.ue.optionspts*1
    return (
      <div className="totalgifts" onClick={()=>{this.props.dispatch(ChangeMode(2))}}>{totalpts}<Icon name="gift" /></div>
    )
  }
  
  render() {
    let comp = null;
    switch (this.props.mode){
      case 0:
        comp = <Promos />;
        break;
        
      case 1:
        comp = <Edit />;
        break;
        
      case 2:
        comp = <Rewards />;
        break;
      
      case 22:
        comp = <Redeem />;
        break;
        
      case 3:
        comp = <Surveys />;
        break;
      default:
        break;
    }
    
    return (
      <div className="app" ref={re=>{this.myRef = re}}>
        <div id="lock">
          Change Your Screen Back to Portrait!
          <div>
            <Icon name="mobile" /><Icon name="sync" />
          </div>
        </div>
        <div className='upper'>
          {comp}
        </div>
        <div className='lower'>
          <MainMenu />
        </div>
        {this.renderPts()}
        {(this.props.load === 1) ?
        <div className="loadScreen">
          <Loader active />
        </div>: null}
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    mode:state.Events.mode,
    ue:state.Events.user.ue,
    load:state.Events.load
  }
}

export default withRouter(connect(mapStateToProps)(MainUI));