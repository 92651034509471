import React, { Component } from 'react';

import {connect} from 'react-redux';
import {Icon} from 'semantic-ui-react';
import { withRouter } from 'react-router';

class RewardsCard extends Component {
  // "#a59fa4"
  constructor(props){
    super(props);
    this.typecolor = ["grey", "#00b5ad", "#FBBD08", "#A333C8", "#6435C9"];
    this.typeicon = ["gift", "gift", "star", "ticket", "question"];
  }
  
  render() {
    return (
       <div className="rcmain" onClick={this.props.redeem}>
        {(!this.props.img || this.props.img === "") ? null : <div className="rcpreview" style={{backgroundImage:"url("+this.props.img+")"}}/>}
        

        <div className="rcname" style={{color:this.typecolor[this.props.type]}}>
          {this.props.name}
        </div>
        <div className="rcpts" style={{backgroundColor:this.typecolor[this.props.type]}}>
          <div style={{textAlign:"center"}}>
            <Icon name={this.typeicon[this.props.type]} style={{fontSize:32}} />
            <div style={{fontSize:18}}>{this.props.pts}<Icon name="gift" style={{marginLeft:5, marginRight:0}}/></div>
            <div style={{fontSize:12}}>
            {
              (this.props.type !== 3 && this.props.type !== 4) ? (this.props.allpts-this.props.pts >= 0) ?
                <div>REDEEM NOW</div> : <div>Not enough points</div> : null
            }
            </div>
          </div>
          {(this.props.type === 2 || this.props.type === 4) ? 
            <div className="rcpromo" style={{color:this.typecolor[this.props.type]}}>
              1X
            </div> : null
          }
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    //rewards:state.Events.user.rewards
    allpts:state.Events.user.ue.totalpts
  }
}

export default withRouter(connect(mapStateToProps)(RewardsCard));