import React, { Component } from 'react';

import {connect} from 'react-redux';
import {ChangeMode} from "../redux/actions/events"
import { withRouter } from 'react-router';

import {Icon} from 'semantic-ui-react';
class Menu extends Component {
  
  changeMode(m){
    this.props.dispatch(ChangeMode(m));
    document.getElementsByClassName("upper")[0].scrollTop = 0;
  }
  render() {
    return (
      <div className="menu">
        <div className={(this.props.mode===0)?"item active":"item"} onClick={this.changeMode.bind(this,0)}>
          <Icon color="blue" size="big" name='home'/>
          <div>Home</div>
        </div>
        <div className={(this.props.mode===1)?"item active":"item"} onClick={this.changeMode.bind(this,1)}>
          <Icon color="yellow" size="big" name='edit'/>
          <div>Profile</div>
        </div>
        <div className={(this.props.mode===3)?"item active":"item"} onClick={this.changeMode.bind(this,3)}>
          <Icon color="orange" size="big" name='game'/>
          <div>Trivia</div>
        </div>
        <div className={(this.props.mode===2)?"item active":"item"} onClick={this.changeMode.bind(this,2)}>
          <Icon color="teal" size="big" name='gift'/>
          <div>Rewards</div>
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    mode:state.Events.mode
  }
}

export default withRouter(connect(mapStateToProps)(Menu));