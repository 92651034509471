import * as types from "../types";
const initialState = {
  load:0,
  mode:0,
  rstatus:null,
  qs:null,
  os:null,
  user:{}
}

export default function events(state=initialState, action){
  let obj = Object.assign({}, state);
  //console.log(action);
  switch(action.type){
      
     case types.CHANGE_MODE:
      obj.mode = action.mode;
      return obj;
      
    case types.LOADING:
      obj.load = 1;
      return obj;
    
    case types.FINISH_LOADING:
      obj.load = 0;
      return obj;
      
    case types.READ_SIGNIN:
      obj.user = action.user;
      return obj;
      
    case types.REDEEM_PRIZE:
      //console.log(action);
      obj.mode = action.mode
      obj.user.ue = action.pr.ue;
      obj.rstatus = action.pr.p;
      obj.user.prizes = action.pr.prizes;
      return obj;
    
    case types.READ_QUESTIONS:
      //console.log(action);
      obj.qs = action.qs;
      obj.os = action.os;
      return obj;
    
    case types.CREATE_OPTIONS:
      //console.log(action);
      obj.user.ue = action.os.ue;
      obj.user.surveys = action.os.surveys;
      return obj;
      
    case types.UPDATE_USER:
      obj.user.ue = action.ue;
      return obj;
    default:
      return state;
  }
}

//
//function ptsSort(a, b) {
//    var apts = parseInt(a.pts, 10);
//    var bpts = parseInt(b.pts, 10);
//    return ((apts < bpts) ? -1 : ((apts > bpts) ? 1 : 0));
//}
//
//function typeSort(a, b) {
//    var apts = parseInt(a.type, 10);
//    var bpts = parseInt(b.type, 10);
//    return ((apts > bpts) ? -1 : ((apts < bpts) ? 1 : 0));
//}