import React, { Component } from 'react';

import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import Carousel from "./subcomp/Carousel";

class Promos extends Component {

  render() {
    var CurPromo = (this.props.promos) ? <Carousel items={this.props.promos} /> : null;
    return (
      <div className="main">
        {CurPromo}
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    promos:state.Events.user.promo
  }
}

export default withRouter(connect(mapStateToProps)(Promos));