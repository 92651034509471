import {mfetch} from '../connect';
import * as types from '../types';

export function ChangeMode(mode){
  return {
    type:types.CHANGE_MODE,
    mode
  }
}

function CheckJson(json, indiv){
  return (json.status === true && json.data && json.data.length > 0) ? (indiv)?json.data[0]:json.data : false;
}

export function ReadHash(hash){
  
  return async (dispatch)=>{
    dispatch({type:types.LOADING});
    var json = await mfetch({param:types.PUBLIC_PATH, data:{hash:hash, type:types.READ_SIGNIN_TYPE}});
    //console.log(json);
    dispatch(FinishReadSignin(CheckJson(json, true)));
    dispatch({type:types.FINISH_LOADING});
    if(json.token){
      types.ChangeToken(json.token);
    }
    try {
      document.title = json.data[0].ue.name;
    } catch (err){
      
    }
  }
}

function FinishReadSignin(user){
  return {
    type:types.READ_SIGNIN,
    user
  }
}

export function ReadQuestions(hash, sid, callback){
  return async (dispatch)=>{
    dispatch({type:types.LOADING});
    var json = await mfetch({param:types.PUBLIC_PATH, data:{
      hash:hash, 
      data:{id:sid}, 
      type:types.READ_QUESTIONS_TYPE}});
    //console.log(json);
    
    if(json.status && json.data && json.data.length>0 && json.options){
      dispatch(FinishReadQuestions(json.data, json.options));
      if(typeof callback === "function"){
        callback(json.data, json.options);
      }
    }
    
    dispatch({type:types.FINISH_LOADING});
  }
}

function FinishReadQuestions(qs, os){
  return {
    type:types.READ_QUESTIONS,
    qs,
    os
  }
}