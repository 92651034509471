import React, { Component } from 'react';

import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {Button, Form} from 'semantic-ui-react';
import {UpdateUser} from '../redux/actions/users';
import Notify from './subcomp/Notify';

class Edit extends Component {
  constructor(props){
    super(props);
    this.state={
      name:this.props.user.fullname,
      email:this.props.user.email,
      active:false
    }
  }

  handleName=(ev, data)=>{
    this.setState({
      name:ev.target.value
    })
  }
  
  handleEmail=(ev, data)=>{
    this.setState({
      email:ev.target.value
    })
  }
  
  handleUpdate=()=>{
    if(this.props.load === 1){
      return false;
    }
    this.props.dispatch(UpdateUser({
      hash:this.props.match.params.hash,
      fullname:this.state.name,
      email:this.state.email
    }, (data)=>{
      this.setState({
        active:true
      })
    }));
  }

  renderNotifyComp=()=>{
    return (
      <div style={{display:"flex"}}>
        <Button style={{flex:1}} onClick={()=>{this.setState({active:false})}}>
          OK
        </Button>
      </div>
    )
  }

  render() {
    return (
      <div className="main editmain">
        <Notify 
          active={this.state.active}
          title="Profile Updated"
          subtitle="Thanks for updating, remember to check out the other features"
          custcomp={this.renderNotifyComp()}
          />
        <Form style={{textAlign:"center", width:"50%"}}>
          <Form.Field>
            Help us by completing your profile to receive emails regarding {this.props.user.name}
          </Form.Field>
          <Form.Field>
            <label>Full Name</label>
            <input placeholder='Full Name' value={this.state.name} onChange={this.handleName}/>
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input placeholder='Email' value={this.state.email} onChange={this.handleEmail} />
          </Form.Field>
          <Button onClick={this.handleUpdate} disabled={this.props.load === 1}>Update</Button>
        </Form>
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    user:state.Events.user.ue,
    load:state.Events.load
  }
}

export default withRouter(connect(mapStateToProps)(Edit));