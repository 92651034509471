import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import MainUI from "./comps/MainUI";
const BoomRouter = () => (
  <Router basename={process.env.PUBLIC_URL}>
    <Switch>
      {/*<Route exact path="/eu/:euid" component={MainUI} />*/}
      <Route exact path="/:hash" component={MainUI} />
    </Switch>
  </Router>
);

export default BoomRouter;