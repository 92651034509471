import React, { Component } from 'react';

import {connect} from 'react-redux';
import { withRouter } from 'react-router';

import SurveysCard from './SurveysCard';
import Notify from '../subcomp/Notify';

import {ReadQuestions} from '../../redux/actions/events';
import {CreateOptions} from '../../redux/actions/users';
import {Button, Icon} from 'semantic-ui-react';

class Surveys extends Component {
  
  constructor(props){
    super(props);
    this.state={
      active:false,
      title:null,
      subtitle:null,
      id:null,
      qind:0,
      curOs:null,
      chosen:{}
    }
    
    this.os = null;
    this.qs = null;
    this.sid = null;
    this.survname = null;
    this.complete = null;
    this.ref = React.createRef();
  }
  
  handleSurveyRead=(sid, survname, complete)=>{
    this.sid = sid;
    this.survname = survname;
    this.complete = complete;
    this.props.dispatch(ReadQuestions(this.props.match.params.hash, sid, (qs, os)=>{
      this.os = os;
      this.qs = qs;
      this.setState({
        active:true,
        qind:0,
        curOs:this.getOpts(0),
        subtitle:(this.complete)?qs[0].answers_desc:null,
        title:qs[0].text
      })
    }));
  }
  
  handleOpts=(obj, qid)=>{
    var chosen = this.state.chosen;
    chosen[qid] = {
      id:obj.id,
      pts:obj.pts
    };
    this.setState({
      chosen:chosen
    });
    this.nextQ();
  }
  
  getOpts=(ind)=>{
    return this.os.filter((obj,i)=>{
      return obj.questions_id === this.qs[ind].id;
    });
  }
  
  nextQ=()=>{
    if(!this.complete && !this.state.chosen[this.qs[this.state.qind].id]){
      this.setState({
        subtitle:"SELECT AN OPTION PLEASE"
      });
      return false;
    }
    //var nind = (this.qs && this.state.qind+1<this.qs.length)?this.state.qind+1:false;
    var nind = (this.state.qind < this.qs.length)?this.state.qind+1:false;
    if(nind >= this.qs.length){
      this.setState({
        qind:nind,
        title:"The End",
        subtitle:"Do you want to submit your answers?"
      })
    } else if(nind !== false){
      this.setState({
        qind:nind,
        title:this.qs[nind].text,
        subtitle:(this.complete)?this.qs[nind].answers_desc:null,
        curOs:this.getOpts(nind)
      });
      
      if(this.ref){
        this.ref.current.scrollTop = 0;
      }
    }
  }
  
  prevQ=()=>{
    var nind = (this.state.qind-1>=0)?this.state.qind-1:false;
    //console.log(nind);
    if(nind !== false){
      this.setState({
        qind:nind,
        title:this.qs[nind].text,
        subtitle:(this.complete)?this.qs[nind].answers_desc:null,
        curOs:this.getOpts(nind)
      });
      if(this.ref){
        this.ref.current.scrollTop = 0;
      }
    }
  }
  
  finish=()=>{
    //console.log(this.state.chosen[this.qs[this.state.qind].id]);
    /*if(!this.complete && !this.state.chosen[this.qs[this.state.qind].id]){
      this.setState({
        subtitle:"SELECT AN OPTION PLEASE"
      });
      return false;
    }*/
    //console.log(this.state.chosen);
    if(this.complete){
      this.setState({
        subtitle:"You've completed "+this.survname,
        title:"Good work",
        qind:"done"
      });
      return false;
    }
    var optionsarr = [];
    for(var i in this.state.chosen){
      optionsarr.push({
        questions_id:i,
        options_id:this.state.chosen[i].id,
        pts:this.state.chosen[i].pts
      })
    }
    
    this.props.dispatch(CreateOptions(this.props.match.params.hash, this.sid, optionsarr, ()=>{
      
      this.setState({
        subtitle:"You've completed "+this.survname,
        title:"Good work",
        qind:"done"
      })
    }));
    

  }
  
  renderQuestion=()=>{
    if(!this.qs){
      return null;
    }
    
    if(this.state.qind === "done"){
      return (<Button onClick={()=>{this.setState({
            active:false,
            qind:0,
            chosen:{}
          })}}>OK</Button>)
    }
    
    if(this.qs && this.state.qind >= this.qs.length){
      return (
        <div>
          <Button icon style={{flex:1}} color="grey" onClick={this.prevQ}>
            <Icon name="left arrow" /> Back
          </Button>
          <Button icon style={{flex:1}} color="green" onClick={this.finish}>
            <Icon name="check" /> Submit
          </Button>
        </div>
      )
    }
    
    var colors = ["orange", "yellow", "olive", "green"],
        ind=0,
        optsbuts = (this.state.curOs && this.state.curOs.length > 0) ? this.state.curOs.map((obj,i)=>{
          ind++;
          if(ind > 3){
            ind=0;
          }
          
          return (
            <Button 
               style={{width:"100%", margin:"5px 0", border:(this.state.chosen[this.qs[this.state.qind].id] && this.state.chosen[this.qs[this.state.qind].id].id === obj.id)?"#DDDFFF solid 5px":""}} 
               key={i} 
               color={colors[ind]} 
               onClick={this.handleOpts.bind(this, obj, this.qs[this.state.qind].id)}>
              {obj.text}
            </Button>
          )
        }) : null;
    
    return (
      <div>
        {(this.qs && this.qs[this.state.qind] && this.qs[this.state.qind].img)?
          <img alt="hero" style={{maxWidth:"100%", maxHeight:"100%"}} src={this.qs[this.state.qind].img}/>
          :null
        }
        
        <div style={{margin:"15px 0"}}>
         {(this.state.qind > 0)?
          <Button icon style={{width:"100%"}} color="grey" onClick={this.prevQ}>
            <Icon name="left arrow" /> Back
            </Button>:null}
        </div>
        
        <div style={{margin:"15px 0"}}>
          {optsbuts}
        </div>
        
        <Icon name="close" inverted circular style={{flex:1, position:"absolute", top:-10, right:-20}} onClick={()=>{this.setState({active:false})}}/>
        
      </div>
    )
  }
  
  render() {
    
    var AllCards = (this.props.surveys) ? this.props.surveys.map((obj,i)=>{
      return <SurveysCard
               complete={obj.complete}
               opts={obj.optionspts}
               img={obj.img}
               title={obj.title}
               desc={obj.description}
               key={i}
               read={this.handleSurveyRead.bind(this, obj.id, obj.title, obj.complete)}
               />
    }) : null;
    return (
      <div className="rewardsmain">
        {AllCards}
        <Notify 
          ref={this.ref}
          active={this.state.active}
          title={this.state.title}
          subtitle={this.state.subtitle}
          custcomp={this.renderQuestion()}
          />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    surveys:state.Events.user.surveys,
    qs:state.Events.qs,
    os:state.Events.os
  }
}

export default withRouter(connect(mapStateToProps)(Surveys));