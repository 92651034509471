import React, { Component } from 'react';

import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {RedeemPrize} from '../../redux/actions/users';
import RewardsCard from './RewardsCard';
import Notify from '../subcomp/Notify';

import {Button} from 'semantic-ui-react';

class Rewards extends Component {
  
  constructor(props){
    super(props);
    this.state={
      active:false,
      title:null,
      subtitle:null,
      id:null,
      redeemable:true
    }
  }
  
  handleNotify(obj){
    //this.props.dispatch(RedeemPrize(this.props.match.params.hash, obj.id));
    this.setState({
      active:true,
      title:obj.name,
      subtitle:obj.description,
      id:obj.id,
      redeemable:obj.redeemable
    });
  }
  
  handleRedeem=()=>{
    this.props.dispatch(RedeemPrize(this.props.match.params.hash, this.state.id));
    this.setState({
      active:false,
      title:null,
      subtitle:null,
      id:null
    });
  }
  
  renderNotifyComp=()=>{
    var comp = null;
    if(this.state.redeemable === 1){
      comp = (
        <div style={{display:"flex"}}>
          <Button style={{flex:1}} onClick={()=>{this.setState({active:false})}}>
            Cancel
          </Button>
          <Button style={{flex:1}} color="teal" onClick={this.handleRedeem}>
            Redeem
          </Button>
        </div>
      )
    } else {
      comp = (
        <div style={{display:"flex"}}>
          <Button style={{flex:1}} onClick={()=>{this.setState({active:false})}}>
            Okay
          </Button>
        </div>
      )
    }
    return comp;
  }
  
  render() {
    
    var AllCards = (this.props.rewards) ? this.props.rewards.map((obj,i)=>{
      return <RewardsCard
               type={obj.type}
               img={obj.img}
               pts={obj.pts}
               name={obj.name}
               desc={obj.desc}
               key={i}
               redeem={this.handleNotify.bind(this, obj)}
               />
    }) : null;
    
    return (
      <div className="rewardsmain">
        {AllCards}
        <Notify 
          active={this.state.active}
          title={this.state.title}
          subtitle={this.state.subtitle}
          custcomp={this.renderNotifyComp()}
          />
      </div>
    )
  }
}


function mapStateToProps(state) {
  return {
    rewards:state.Events.user.prizes
  }
}

export default withRouter(connect(mapStateToProps)(Rewards));