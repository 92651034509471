import React from 'react';
import {Dimmer, Header} from 'semantic-ui-react';

import anchorme from "anchorme";

const Notify =  React.forwardRef((props, ref) => {
  var comp = (props.custcomp)?props.custcomp:false;
  var subtitle = (props.subtitle)? anchorme(props.subtitle, 
  {
    attributes:[
      {
        name:"target",
        value:"_blank"
      }]
  }) : null;
  var subparts = [];
  
  if(subtitle){
    subparts = subtitle.split(/\<a.*\/a>/);
    
    if(subparts.length > 0){
      var anchors = subtitle.match(/\<a.*\/a>/);
      if(anchors && anchors.length > 0){
        anchors = (<span className='notifAnchor' key="anc" dangerouslySetInnerHTML={{__html: anchors[0]}}></span>);
        subparts.splice(1, 0, anchors);
      }
    }
  }
  //console.log(subparts);
  return (
    <Dimmer className="notifMain" active={props.active} page style={{backgroundColor:"rgba(0,0,0,0.4)"}}>
      <div className="notifBox">
        <div className="notifContent" ref={ref}>
          <Header as='h2' className="notifHead">
            {props.title}
            <Header.Subheader>{subparts}</Header.Subheader>
          </Header>
          <div className="notifComp">
            {comp}
          </div>
        </div>
      </div>
    </Dimmer>
  )
});

export default Notify;