import React, { Component } from 'react';

import './styles/App.scss';
import thunkMiddleware from 'redux-thunk'
import {Provider} from "react-redux";
import {createStore, applyMiddleware, compose} from "redux";
import reducers from "./redux/reducers/reducers";
import 'semantic-ui-css/semantic.min.css';

import Router from "./Router";
import {DEV} from './redux/connect';

const composeEnhancers =
  typeof window === 'object' && DEV &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    }) : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware),
  // other store enhancers if any
);

const store = createStore(
  reducers,
  enhancer
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router />
      </Provider>
    );
  }
}

export default App;
